import { TextLabels } from "types/textLabels";

export const flusLabels: TextLabels = {
  wizardStep2_linePricingDownloadFile_endpoint: "/line-pricing/download",
  wizardStep2_linePricingDownloadFile: "Line-Pricing-Grouping",
  wizardStep2_linePricing: "Line Pricing",
  wizardStep2_linePricingDownload: "Download Line Price Groupings",
  wizardStep2_linePricingOptions: "Line price BU/ Sizes",
  wizardStep2_linePricingTooltip:
    "When off, each BU size will be priced independently. When on, custom line pricing will be followed while optimizing.",
  wizardStep2_minVolume: "Min Volume (lbs)",
  wizardStep2_minNetRevenue: "Min Net Rev per Pound ($/lb)",
  wizardStep2_minNetRevTooltip:
    "Minimum net revenue per lb allowed in current scenario above or below reference (e.g. 98% allows for 2% de-pricing than reference scenario across portfolio)",
  wizardStep2_maxNetRevenue: "Max Net Rev per Pound ($/lb)",
  wizardStep2_maxNetRevTooltip:
    "Maximum net revenue per lb allowed in current scenario above or below reference (e.g. 102% allows for 2% pricing than reference scenario across portfolio)",
  wizardStep3_HeadCells_subCategory: "SUBCATEGORY",
  wizardStep3_HeadCells_bu: "BU",
  wizardStep3_HeadCells_buSize: "BU SIZE",
  wizardStep3_HeadCells_actualPackSize: "PACK SIZE (OZ)",
  wizardStep3_HeadCells_weightOut: "WEIGHT OUT (OZ)",
  wizardStep5_weightOut: "Weight Outs",
  wizardStep5_weightUnit: "oz",
  scenarioList_grossProfit_line1: "Gross",
  scenarioList_grossProfit_line2: "Profit ($ B)",
  scenarioList_netRevenue_line1: "Net",
  scenarioList_netRevenue_line2: "Revenue ($ B)",
  scenarioList_rsv_line1: "RSV ($ B)",
  scenarioList_rsv_line2: "",
  scenarioList_units_line1: "Units (MM)",
  scenarioList_units_line2: "",
  scenarioList_volume_line1: "Volume",
  scenarioList_volume_line2: "(MM LBS)",
  scenarioList_volumeShare_line1: "Volume",
  scenarioList_volumeShare_line2: "Share",
  scenarioList_marketShare_line1: "Market",
  scenarioList_marketShare_line2: "Share",
  wizardStep4_buSize: "BU Size",
  wizardStep4_linePriceGroup: "Line Price Group",
  wizardStep4_linePrice: "line price",
  wizardStep4_symbol: "%",
};

export const pmfLabels: TextLabels = {
  wizardStep2_linePricingDownloadFile_endpoint: "/tier-pricing/download",
  wizardStep2_linePricingDownloadFile: "Price-Tier-Rules",
  wizardStep2_linePricing: "Price Tiers",
  wizardStep2_linePricingDownload: "Download Price Tier Rules",
  wizardStep2_linePricingOptions: "Enable Price Tiers",
  wizardStep2_linePricingTooltip:
    "When off, each brand pack will be priced independently. When on, price tier rules will be followed while optimizing.",
  wizardStep2_minVolume: "Min Volume (kg)",
  wizardStep2_minNetRevenue: "Min Net Rev per kg ($/kg)",
  wizardStep2_minNetRevTooltip:
    "Minimum net revenue per kg allowed in current scenario above or below reference (e.g. 98% allows for 2% de-pricing than reference scenario across portfolio)",
  wizardStep2_maxNetRevenue: "Max Net Rev per kg ($/kg)",
  wizardStep2_maxNetRevTooltip:
    "Maximum net revenue per kg allowed in current scenario above or below reference (e.g. 102% allows for 2% pricing than reference scenario across portfolio)",
  wizardStep3_HeadCells_subCategory: "SUBCATEGORY",
  wizardStep3_HeadCells_bu: "SUB BRAND",
  wizardStep3_HeadCells_buSize: "BRAND PACKS",
  wizardStep3_HeadCells_actualPackSize: "PACK SIZE (G)",
  wizardStep3_HeadCells_weightOut: "WEIGHT CHANGE (G)",
  wizardStep5_weightOut: "Weight Change",
  wizardStep5_weightUnit: "g",
  scenarioList_grossProfit_line1: "Gross",
  scenarioList_grossProfit_line2: "Profit ($ B)",
  scenarioList_netRevenue_line1: "Net",
  scenarioList_netRevenue_line2: "Revenue ($ B)",
  scenarioList_rsv_line1: "RSV ($ B)",
  scenarioList_rsv_line2: "",
  scenarioList_units_line1: "Units (MM)",
  scenarioList_units_line2: "",
  scenarioList_volume_line1: "Volume",
  scenarioList_volume_line2: "(MM KG)",
  scenarioList_volumeShare_line1: "Volume",
  scenarioList_volumeShare_line2: "Share",
  scenarioList_marketShare_line1: "Market",
  scenarioList_marketShare_line2: "Share",
  wizardStep4_buSize: "Brand Packs",
  wizardStep4_linePriceGroup: "Price Tiers",
  wizardStep4_linePrice: "price tiers",
  wizardStep4_symbol: "$",
};
