// Add third-party dependencies.
import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
// eslint-disable-next-line import/no-extraneous-dependencies, import/no-unresolved
import * as FullStory from "@fullstory/browser"

import App from "./App";
import theme from "./styles/theme";
// eslint-disable-next-line import/no-unresolved
import "pepsico-ds/css";

import { FULLSTORY_ORGID } from "config/config";

// Initialize FullStory
if (FULLSTORY_ORGID) {
  FullStory.init(
    { orgId: FULLSTORY_ORGID },
    ({ sessionUrl }) =>
      console.log(`Started session: ${sessionUrl}`),
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>  
    </BrowserRouter>
  </React.StrictMode>,
);
