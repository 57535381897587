import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  Drawer as MuiDrawer,
  List,
  ListItemButton,
  ListItemIcon,
  styled,
  Toolbar,
  Theme,
  CSSObject,
  ListItemText,
  Collapse,
} from "@mui/material";
import BurgerIcon from "assets/icons/BurgerIcon";
import HomeIcon from "@mui/icons-material/Home";
import InsightsIcon from "@mui/icons-material/Insights";
import CalculateIcon from "@mui/icons-material/Calculate";
import FolderIcon from "@mui/icons-material/Folder";
import CloseIcon from "@mui/icons-material/Close";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import { useLocation, useNavigate } from "react-router-dom";

import PepsiIcon from "assets/icons/PepsiIcon";
import { ENV_UI_OPTIMIZATION } from "config/config";
import { DateRange, ExpandLess, ExpandMore } from "@mui/icons-material";

import "./NavBar.scss";
import { nestedSupportLinks } from "./NavBar.constants";
import { AuthorizeUserContext } from "contexts/AuthorizeUserContext";
import FeatureFlag from "components/feature_flag/FeatureFlag";

const openDrawerWidth = 252;
const closedDrawerWidth = 80;

interface MenuItem {
  icon: JSX.Element;
  path: string;
  highlight?: boolean;
  label?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  linkTo?: boolean;
  hamburgerIconIsClicked?: boolean;
  allowedForMarkets?: number[];
}

const iconStyle = {
  color: "#616161",
  fontSize: "19px",
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: openDrawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflow: "hidden",
  borderColor: "transparent",
  backgroundColor: "#fbfcfe",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflow: "hidden",
  width: closedDrawerWidth,
  borderColor: "transparent",
  backgroundColor: "#fbfcfe",
});

const StyledDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundColor: "#fbfcfe",
  width: openDrawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const createStyledListItemButton = styled(ListItemButton);
const StyledListItemButton = createStyledListItemButton(() => ({
  padding: "0.9rem",
  borderLeft: "0.5rem solid transparent",
  "&:hover": {
    color: "#005cbc",
    backgroundColor: "#dfeaf9",
  },
  "&.Mui-selected": {
    backgroundColor: "unset",
    borderLeft: "0.5rem solid #005cbc",
    ".icon": {
      fill: "#005cbc",
    },
  },
}));

const createStyledListItemIcon = styled(ListItemIcon);
const StyledListItemIcon = createStyledListItemIcon({
  minWidth: 0,
  justifyContent: "center",
});

const StyledNestedListItemButton = createStyledListItemButton(({ theme }) => ({
  paddingTop: "0.4rem",
  paddingBottom: "0.4rem",
  paddingLeft: "0.9rem",
  backgroundColor: "var(--blue-22)",
  "&:hover": {
    color: theme.palette.primary.main,
    backgroundColor: "var(--blue-29)",
  },
  "&.Mui-selected": {
    color: theme.palette.primary.main,
    backgroundColor: "var(--blue-29)",
  },
}));

export const NavBar: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [selectedIndex, setSelectedIndex] = useState<number>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [navIcon, setNavIcon] = useState(<BurgerIcon className="icon" sx={iconStyle} />);
  const [supportOpen, setSupportOpen] = useState<boolean>(false);
  const { authorizeUserDetails: user } = useContext(AuthorizeUserContext);

  const renderIcon = useMemo(() => {
    return supportOpen ? (
      <ExpandLess sx={{ ml: "auto", fontSize: "19px" }} />
    ) : (
      <ExpandMore sx={{ ml: "auto", fontSize: "19px" }} />
    );
  }, [supportOpen]);

  useEffect(() => {
    switch (true) {
      case pathname.includes("/simulation") || pathname === "/":
        setSelectedIndex(1);
        break;
      case pathname.includes("/scenarios"):
        setSelectedIndex(2);
        break;
      case pathname.includes("/pricingSimulation"):
        setSelectedIndex(3);
        break;
      case pathname.includes("/scenarios/folders"):
        setSelectedIndex(4);
        break;
      default:
        setSelectedIndex(null); // Set default index if none of the conditions match
        break;
    }
  }, []);

  useEffect(() => {
    if (open) {
      setNavIcon(<CloseIcon className="icon" sx={iconStyle} />);
    } else {
      setNavIcon(<BurgerIcon className="icon" sx={iconStyle} />);
    }
  }, [open]);

  const handleSupportClick = useCallback(() => {
    if (open) {
      setSupportOpen((prev) => !prev);
    } else {
      setOpen(true);
      setSupportOpen(true);
    }
  }, [open, setOpen, setSupportOpen]);

  const menuItems: MenuItem[] = [
    {
      icon: navIcon,
      path: null,
      hamburgerIconIsClicked: true,
      label: `${open ? "Close" : ""}`,
    },
    {
      icon: <HomeIcon className="icon" sx={iconStyle} />,
      path: "/simulation",
      label: "Dashboard",
      allowedForMarkets: [1, 2, 3],
    },
    {
      icon: <InsightsIcon className="icon" sx={iconStyle} />,
      path: "/scenarios",
      label: "Optimization",
      allowedForMarkets: [1, 2],
    },
    {
      icon: <CalculateIcon className="icon" sx={iconStyle} />,
      path: "/pricingSimulation",
      label: "Simulation",
      allowedForMarkets: [3],
    },
    {
      icon: <FolderIcon className="icon" sx={iconStyle} />,
      path: "/scenarios/folders",
      label: "Saved Scenarios",
      allowedForMarkets: [1, 2],
    },
  ];

  const allowedMarkets = user?.userAccessRoles?.filter((role) => role.appId === 2).map((role) => role.marketId);
  const finalMenuItems = menuItems.filter(
    (item) => item.allowedForMarkets?.some((market) => allowedMarkets?.includes(market)) || !item.allowedForMarkets,
  );

  if (user?.isAdmin) {
    finalMenuItems.push({
      icon: <SettingsApplicationsIcon className="icon" sx={iconStyle} />,
      path: `${ENV_UI_OPTIMIZATION}`,
      label: "Admin",
      linkTo: true,
    });
  }

  if (user?.hasPromoAccess) {
    finalMenuItems.push({
      icon: <DateRange className="icon" sx={iconStyle} />,
      path: `${ENV_UI_OPTIMIZATION}`,
      label: "Promo.ai",
      linkTo: true,
    });
  }

  const supportItem = useMemo(() => {
    const supportSelected = pathname.includes("/support");

    const nestedItems = nestedSupportLinks.map(({ name, path }) => (
      <StyledNestedListItemButton
        sx={{ justifyContent: open ? "initial" : "center" }}
        key={name}
        selected={pathname === path}
        onClick={() => {
          setSelectedIndex(null);
          navigate(path);
        }}
      >
        <ListItemIcon />
        <ListItemText
          hidden={!open}
          primary={name}
          disableTypography={true}
          sx={{ fontSize: "1.3rem" }}
          className={supportSelected ? "selected-item" : "unselected-item"}
        />
      </StyledNestedListItemButton>
    ));

    return (
      <>
        <StyledListItemButton
          disableRipple
          disableTouchRipple
          selected={supportSelected}
          onClick={handleSupportClick}
          sx={{
            justifyContent: open ? "initial" : "center",
          }}
        >
          <StyledListItemIcon
            sx={{
              mr: open ? 2 : 0,
              height: "3.375rem",
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              padding: "0",
              width: "5rem",
            }}
          >
            <HelpCenterIcon className="icon" sx={iconStyle} />
          </StyledListItemIcon>
          <ListItemText
            hidden={!open}
            primary={"Support"}
            disableTypography={true}
            className={supportSelected ? "selected-item" : "unselected-item"}
          />
          {open ? renderIcon : null}
        </StyledListItemButton>
        <Collapse in={supportOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {nestedItems}
          </List>
        </Collapse>
      </>
    );
  }, [pathname, handleSupportClick, open, renderIcon, supportOpen, navigate]);

  const buttonProps = (value, path, linkTo, hamburgerIconIsClicked) => ({
    selected: selectedIndex === value,
    onClick: () => {
      if (hamburgerIconIsClicked) {
        setOpen(!open);
        setSupportOpen(false);
      } else if (!linkTo) {
        setSelectedIndex(value);
        navigate(path);
      } else {
        window.open(path);
      }
    },
  });

  return (
    <StyledDrawer variant="permanent" open={open} aria-label="drawer">
      <Toolbar />
      <List disablePadding>
        {finalMenuItems.map((item: MenuItem, index) => {
          return (
            <StyledListItemButton
              key={item.label}
              disableRipple
              disableTouchRipple
              sx={{
                justifyContent: open ? "initial" : "center",
              }}
              {...buttonProps(index, item.path, item.linkTo, item.hamburgerIconIsClicked)}
            >
              <StyledListItemIcon
                sx={{
                  mr: open ? 2 : 0,
                  height: "3.375rem",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  padding: "0",
                  width: "5rem",
                }}
              >
                {item.icon}
              </StyledListItemIcon>
              <ListItemText
                hidden={!open}
                primary={item.label}
                disableTypography={true}
                className={
                  (selectedIndex === index ? "selected-item" : "unselected-item") +
                  (index === 0 ? " close-icon-text" : "")
                }
              />
            </StyledListItemButton>
          );
        })}
        <FeatureFlag name="support-menu">{supportItem}</FeatureFlag>
      </List>
      <PepsiIcon
        sx={{
          width: "104px",
          height: "110px",
          bottom: "-1.813rem",
          cursor: "pointer",
          left: "-0.5rem",
          position: "absolute",
        }}
      />
    </StyledDrawer>
  );
};

export default NavBar;
