// Add third-party dependencies.
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Local dependencies.
import { RootState } from "utils/store";
import { TextLabels } from "types/textLabels";
import { MarketName } from "types/markets";
// import { folderapiSlice } from "./folderapiSlice";
// import { scenarioapiSlice } from "./scenarioapiSlice";
import { flusLabels, pmfLabels } from "constants/textLabels";
import { getUpdateScenarioReferences, hasSubCatBuSizeWO } from "utils/helpers/addScenarioHelper";
import {
  LinePricingData,
  IAddScenario,
  ISubCatFinConstraints,
  IAddScenarioSubCategories,
  IAddScenarioCategories,
  IScenarioReferences,
} from "types/addScenario";

/* =========================================
                Types           
==========================================*/
export type include = "include";
export type subCatInclude = Record<include, boolean>;
export type minMaxErrorIndexesType = Record<string, Record<number, Record<"min" | "max", string>>>;
export type AddScenarioType = {
  newScenario: IAddScenario;
  minMaxErrorIndexes: minMaxErrorIndexesType;
  reset: boolean;
  isMarketPMF?: boolean;
  scenarioLabels?: TextLabels;
};
export type UpdateFinConstrPayload = {
  constrType: string;
  constrName: string;
  value: number | boolean | ISubCatFinConstraints[];
}[];
export enum VisualPriceTextType {
  Maximum = "Maximum",
  Minimum = "Minimum",
}
/* =========================================
            initial State           
==========================================*/
export const initialState: AddScenarioType = {
  newScenario: null,
  minMaxErrorIndexes: null,
  reset: false,
  isMarketPMF: null,
  scenarioLabels: null,
};
/* =========================================
            Slice           
==========================================*/
const addScenarioSlice = createSlice({
  name: "addScenario",
  initialState,
  reducers: {
    setNewScenario: (state, action: PayloadAction<IAddScenario>) => {
      state.newScenario = action.payload;
    },
    updateEmailNotificationStatus: (state, action: PayloadAction<boolean>) => {
      state.newScenario.isEmailNotification = action.payload;
    },
    updateOptTarget: (state, action: PayloadAction<{ value: string }>) => {
      state.newScenario.optTarget = action.payload.value;
    },
    updateFinancialConstraints: (state, action: PayloadAction<UpdateFinConstrPayload>) => {
      action.payload.forEach(({ constrType, constrName, value }) => {
        if (constrName) state.newScenario.financialConstraints[constrType][constrName] = value;
        else state.newScenario.financialConstraints[constrType] = value;
      });
      // const { constrType, constrName, value } = action.payload;
      // state.newScenario.financialConstraints[constrType][constrName] = value;
    },
    updateWeightChangeFlag: (state, action: PayloadAction<boolean>) => {
      state.newScenario.isWeightChanges = action.payload;
    },
    updateIndustryValue: (state, action: PayloadAction<{ value: string }>) => {
      state.newScenario.industry = action.payload.value;
    },
    resetScenarioSubcatWeightOut: (state, action: PayloadAction<{ scenarioCategories: string[] }>) => {
      action.payload.scenarioCategories.forEach((scenCat) => {
        state.newScenario.scenarioCat[scenCat].subCats.forEach((subCat) => {
          subCat.weightOut = 0;
        });
      });
    },
    updateScenarioSubcatWeightOut: (
      state,
      action: PayloadAction<{ value: number; subcategory: string; buSize: string }>,
    ) => {
      const { value, subcategory, buSize } = action.payload;
      const requiredSubCat = state.newScenario.scenarioCat[subcategory].subCats.find(
        (subCat) => subCat.buSize === buSize,
      );

      if (requiredSubCat) {
        requiredSubCat.weightOut = value;
      }
    },
    updateScenarioSubcatLinePriceGrp: (
      state,
      action: PayloadAction<{ buSizeLinePriceMap: Record<string, LinePricingData> }>,
    ) => {
      Object.keys(state.newScenario.scenarioCat).forEach((scenCat) => {
        state.newScenario.scenarioCat[scenCat].subCats.forEach((subCat) => {
          const linePriceDatas = action.payload.buSizeLinePriceMap[subCat.buSize];
          if (linePriceDatas) {
            subCat.linePriceGroup = linePriceDatas?.priceGroup || "";
          }
        });
      });
    },
    updateScenarioSubcatIncludeVals: (
      state,
      action: PayloadAction<{ category: string; subCatIncludeVals: subCatInclude[] }>,
    ) => {
      const { category, subCatIncludeVals } = action.payload;
      state.newScenario.scenarioCat[category].subCats.forEach((subCat, index) => {
        if (subCatIncludeVals?.[index]?.include !== undefined) subCat.include = subCatIncludeVals[index].include;
      });
    },
    updateScenarioCatDatas: (
      state,
      action: PayloadAction<{ scenarioCatNewDatas: Record<string, IAddScenarioCategories> }>,
    ) => {
      state.newScenario.scenarioCat = {
        ...state.newScenario.scenarioCat,
        ...action.payload.scenarioCatNewDatas,
      };
    },
    updateDatasUponCatTextValChange: (
      state,
      action: PayloadAction<{
        scenarioCat: string;
        catTextVal: number;
        textValType: VisualPriceTextType;
        linePricingIsOn: boolean;
      }>,
    ) => {
      const { scenarioCat, catTextVal, textValType, linePricingIsOn } = action.payload;
      const currCategoryDatas = state.newScenario.scenarioCat[scenarioCat];

      if (textValType === VisualPriceTextType.Minimum) {
        currCategoryDatas.parentMin = catTextVal;
      } else if (textValType === VisualPriceTextType.Maximum) {
        currCategoryDatas.parentMax = catTextVal;
      }

      // Updating subCat textBoxes
      currCategoryDatas.subCats.forEach((currCatSubCat) => {
        if (linePricingIsOn) {
          Object.keys(state.newScenario.scenarioCat).forEach((scenCat) => {
            state.newScenario.scenarioCat[scenCat].subCats.forEach((subCat) => {
              if (subCat?.linePriceGroup === currCatSubCat.linePriceGroup && subCat?.include) {
                subCat[textValType] = catTextVal;
              }
            });
          });
        } else {
          currCatSubCat[textValType] = catTextVal;
        }
      });
    },
    updateDatasUponSubcatTextChange: (
      state,
      action: PayloadAction<{
        scenarioCat: string;
        subCatPosition: number;
        subCatTextVal: number;
        textValType: VisualPriceTextType;
        linePricingIsOn: boolean;
      }>,
    ) => {
      const { scenarioCat, subCatPosition, subCatTextVal, textValType, linePricingIsOn } = action.payload;

      const currSubCat = state.newScenario.scenarioCat[scenarioCat].subCats[subCatPosition];
      if (linePricingIsOn) {
        Object.keys(state.newScenario.scenarioCat).forEach((scenCat) => {
          state.newScenario.scenarioCat[scenCat].subCats.forEach((subCat) => {
            if (subCat?.linePriceGroup === currSubCat.linePriceGroup && subCat?.include) {
              subCat[textValType] = subCatTextVal;
            }
          });
        });
      } else {
        currSubCat[textValType] = subCatTextVal;
      }
    },
    updateDatasUponSubcatCheckboxClick: (
      state,
      action: PayloadAction<{
        scenarioCat: string;
        subCatPosition: number;
        subCatCheckedStatus: boolean;
        linePricingIsOn: boolean;
      }>,
    ) => {
      const { scenarioCat, subCatPosition, subCatCheckedStatus, linePricingIsOn } = action.payload;

      const currSubCat = state.newScenario.scenarioCat[scenarioCat].subCats[subCatPosition];
      // Updating checked status for current subCategory.
      currSubCat.include = subCatCheckedStatus;
      // Updating min and max vals for current subCategory.
      Object.keys(state.newScenario.scenarioCat).forEach((scenCat) => {
        if (scenCat !== scenarioCat) {
          state.newScenario.scenarioCat[scenCat].subCats.forEach((subCat) => {
            if (subCat?.include && subCat?.buSize === currSubCat?.buSize) {
              currSubCat.Maximum = subCat?.Maximum;
              currSubCat.Minimum = subCat?.Minimum;
            }
          });
        }
      });

      // Updating datas for other subCategories
      if (linePricingIsOn) {
        Object.keys(state.newScenario.scenarioCat).forEach((scenCat) => {
          state.newScenario.scenarioCat[scenCat].subCats.forEach((subCat) => {
            if (subCat?.linePriceGroup === currSubCat.linePriceGroup) {
              subCat.include = subCatCheckedStatus;
              subCat.Maximum = currSubCat.Maximum;
              subCat.Minimum = currSubCat.Minimum;
            }
          });
        });
      }

      // Updating category level included datas
      Object.keys(state.newScenario.scenarioCat).forEach((scenCat) => {
        let includedSubCats = 0;
        state.newScenario.scenarioCat[scenCat].subCats.forEach((subCat) => {
          if (subCat.include) {
            includedSubCats += 1;
          }
        });
        state.newScenario.scenarioCat[scenCat].includedTotal = includedSubCats;
      });
    },
    updateDatasUponCatCheckboxClick: (
      state,
      action: PayloadAction<{
        scenarioCat: string;
        catCheckedStatus: boolean;
        buSizeWO: string[];
        isMarketPMF: boolean;
        linePricingIsOn: boolean;
      }>,
    ) => {
      const { scenarioCat, catCheckedStatus, buSizeWO, isMarketPMF, linePricingIsOn } = action.payload;

      const currCategoryDatas = state.newScenario.scenarioCat[scenarioCat];

      currCategoryDatas.subCats.forEach((currCatSubCat) => {
        if (!hasSubCatBuSizeWO(currCatSubCat.weightOut, currCatSubCat.buSize, buSizeWO)) {
          //Updating subCat textBoxes
          if (catCheckedStatus && !isMarketPMF) {
            currCatSubCat.Minimum = Number(currCategoryDatas.parentMin);
            currCatSubCat.Maximum = Number(currCategoryDatas.parentMax);
          }
          //Updating subCat checkbox
          currCatSubCat.include = catCheckedStatus;
          //Updating other subCats with same linePriceGroup
          if (linePricingIsOn) {
            Object.keys(state.newScenario.scenarioCat).forEach((scenCat) => {
              state.newScenario.scenarioCat[scenCat].subCats.forEach((subCat) => {
                if (subCat?.linePriceGroup === currCatSubCat.linePriceGroup) {
                  subCat.include = catCheckedStatus;
                  subCat.Minimum = isMarketPMF ? subCat.Minimum : Number(currCategoryDatas.parentMin);
                  subCat.Maximum = isMarketPMF ? subCat.Maximum : Number(currCategoryDatas.parentMax);
                }
              });
            });
          }
        }
      });

      //Updating category level included datas
      Object.keys(state.newScenario.scenarioCat).forEach((scenCat) => {
        let includedSubCats = 0;
        state.newScenario.scenarioCat[scenCat].subCats.forEach((subCat) => {
          if (subCat.include) {
            includedSubCats += 1;
          }
        });
        state.newScenario.scenarioCat[scenCat].includedTotal = includedSubCats;
      });
    },
    sortScenarioCatSubcat: (state, action: PayloadAction<{ sortType: string; basedOnParam: string }>) => {
      const { sortType, basedOnParam } = action.payload;
      const sortFunc = (fst: IAddScenarioSubCategories, snd: IAddScenarioSubCategories) => {
        const paramValofFst = fst?.[basedOnParam]?.toLowerCase() || "";
        const paramValofSnd = snd?.[basedOnParam]?.toLowerCase() || "";

        if (sortType === "ASC") {
          return paramValofFst - paramValofSnd;
        } else if (sortType === "DESC") {
          return paramValofSnd - paramValofFst;
        }
      };
      Object.keys(state.newScenario.scenarioCat).forEach((scenCat) => {
        const oldSubCats = state.newScenario.scenarioCat[scenCat].subCats;
        const updatedSubCats = [...oldSubCats].sort(sortFunc);
        state.newScenario.scenarioCat[scenCat].subCats = updatedSubCats;
      });
    },
    updateNewScenarioName: (state, action: PayloadAction<string>) => {
      state.newScenario.name = action.payload;
    },
    updateNewScenarioDesc: (state, action: PayloadAction<string>) => {
      state.newScenario.description = action.payload;
    },
    setMinMaxErrorIndexes: (state, action: PayloadAction<minMaxErrorIndexesType>) => {
      state.minMaxErrorIndexes = action.payload;
    },
    setReset: (state, action: PayloadAction<boolean>) => {
      state.reset = action.payload;
    },
    updateReferenceScenarioDatas: (state, action: PayloadAction<IScenarioReferences>) => {
      const updatedReferences = getUpdateScenarioReferences(state.newScenario.references, action.payload);
      state.newScenario.references = { ...state.newScenario.references, ...updatedReferences };
    },
    updateMarketBasedDatas: (state, action: PayloadAction<{ market: string }>) => {
      const { market } = action.payload;
      if (market) {
        const isPMF = market === MarketName.PMF;
        state.isMarketPMF = isPMF;
        state.scenarioLabels = isPMF ? pmfLabels : flusLabels;
      }
    },
  },
  //   extraReducers: (builder) => {
  //     builder.addMatcher(folderapiSlice.endpoints.getFoldersbyId.matchFulfilled, (state, action) => {
  //       const { payload } = action;
  //       if (payload.market) {
  //         const isPMF = payload.market === MarketName.PMF;
  //         state.isMarketPMF = isPMF;
  //         state.scenarioLabels = isPMF ? pmfLabels : flusLabels;
  //       }
  //     });
  //     builder.addMatcher(scenarioapiSlice.endpoints.getReferenceValues.matchFulfilled, (state, action) => {
  //       const { payload } = action;
  //       const updatedReferences = getUpdateScenarioReferences(state.newScenario.references, payload);
  //       state.newScenario.references = { ...state.newScenario.references, ...updatedReferences };
  //     });
  //   },
});
/* =========================================
                Selectors            
==========================================*/
export const selectNewScenario = (state: RootState) => state.addScenario.newScenario;
export const getVisualPriceChangeData = (state: RootState) => state.addScenario?.newScenario?.scenarioCat;

/* =========================================
            Actions           
==========================================*/
export const {
  setNewScenario,
  updateEmailNotificationStatus,
  updateOptTarget,
  updateFinancialConstraints,
  updateWeightChangeFlag,
  updateIndustryValue,
  resetScenarioSubcatWeightOut,
  updateScenarioSubcatWeightOut,
  updateScenarioSubcatLinePriceGrp,
  updateScenarioSubcatIncludeVals,
  updateScenarioCatDatas,
  //Update market based datas
  updateMarketBasedDatas,
  //Updating ref datas
  updateReferenceScenarioDatas,
  // For Cat datas in Step 4
  updateDatasUponCatCheckboxClick,
  updateDatasUponCatTextValChange,
  // For subCat datas in Step 4
  updateDatasUponSubcatCheckboxClick,
  updateDatasUponSubcatTextChange,
  sortScenarioCatSubcat,
  updateNewScenarioName,
  updateNewScenarioDesc,
  setMinMaxErrorIndexes,
  setReset,
} = addScenarioSlice.actions;
/* =======================================
           Export Reducer  
===========================================*/
export default addScenarioSlice.reducer;
