import { IAddScenario, IScenarioReferences, IAddScenarioCategories } from "types/addScenario";
import { Product } from "types/result";
import { Folder } from "types/folder";
import { Scenario } from "types/scenario";

export const getDefaultData = (
  products: Product[],
  folderData: Folder,
  scenarioData?: Scenario,
  newCategoryStrucutre?: boolean,
) => {
  const buSizeFrequencies: Record<string, { freq: number; min: number; max: number; include: boolean }> = {};
  const industryMovement = {
    1: "Yes",
    0: "No",
  };
  const subCatFinConstraints = newCategoryStrucutre
    ? [
        { subCategory: "Potato Chips", minNetSales: 0, minGrossMargin: 0, minVolume: 0, maxNetRevenue: 200 },
        { subCategory: "Tortilla Chips", minNetSales: 0, minGrossMargin: 0, minVolume: 0, maxNetRevenue: 200 },
        { subCategory: "Multi Packs", minNetSales: 0, minGrossMargin: 0, minVolume: 0, maxNetRevenue: 200 },
      ]
    : [
        { subCategory: "Potato Chips", minNetSales: 0, minGrossMargin: 0, minVolume: 0, maxNetRevenue: 200 },
        {
          subCategory: "Unflavored Tortilla Chips",
          minNetSales: 0,
          minGrossMargin: 0,
          minVolume: 0,
          maxNetRevenue: 200,
        },
        { subCategory: "Flavored Tortilla Chips", minNetSales: 0, minGrossMargin: 0, minVolume: 0, maxNetRevenue: 200 },
        { subCategory: "Multi Packs", minNetSales: 0, minGrossMargin: 0, minVolume: 0, maxNetRevenue: 200 },
      ];

  const scenario: IAddScenario = {
    scenarioId: scenarioData?.scenarioId || null,
    scenarioType: scenarioData?.scenarioType || null,
    name: scenarioData?.name || null,
    description: scenarioData?.description || null,
    buSizeFrequencies: null,
    scenarioFolder: {
      name: folderData?.name || null,
      folderId: folderData?.folderId || null,
      market: folderData?.market || null,
    },
    optTarget: scenarioData?.maximizationGoal || "cpg_profit",
    financialConstraints: {
      catFinConstraints: {
        minNetSales: scenarioData?.netSales ?? 100,
        minGrossProfit: scenarioData?.grossMargin ?? 100,
        minVolume: scenarioData?.volume ?? 100,
        minNetRevenue: scenarioData?.minNetRevPerMktUnit ?? 100,
        maxNetRevenue: scenarioData?.maxNetRevPerMktUnit ?? 200,
        minMarketShare: scenarioData?.marketShare ?? 0,
      },
      occasions: {
        takeHome: false,
        multiPack: false,
      },
      subCatFinConstraints,
      pricingConstraints: {
        brandSize:
          typeof scenarioData?.inputPricingConstraints?.linePricingBrandSize !== "undefined"
            ? scenarioData?.inputPricingConstraints?.linePricingBrandSize
            : true,
        incentiveCurves: scenarioData?.inputPricingConstraints?.incentivesCurvesMinGap || 5,
      },
    },
    isWeightChanges: scenarioData?.isWeightChange || false,
    isEmailNotification: scenarioData?.isEmailNotification || false,
    industry: industryMovement[scenarioData?.competitorResponse] || null,
    scenarioCat: {},
    references: {
      referenceFinancialConstraints: {
        netSales: "-",
        grossProfit: "-",
        volume: "-",
        minNetRevPerMktUnit: "-",
        maxNetRevPerMktUnit: "-",
        pepMktShr: "-",
      },
      subCategoryFinancialConstraints: [
        {
          category: "Mpack",
          netSales: "-",
          grossProfit: "-",
          volume: "-",
          minNetRevPerMktUnit: "-",
          maxNetRevPerMktUnit: "-",
        },
        {
          category: "Potato Chips",
          netSales: "-",
          grossProfit: "-",
          volume: "-",
          minNetRevPerMktUnit: "-",
          maxNetRevPerMktUnit: "-",
        },
        {
          category: "Tortilla Chips",
          netSales: "-",
          grossProfit: "-",
          volume: "-",
          minNetRevPerMktUnit: "-",
          maxNetRevPerMktUnit: "-",
        },
        {
          category: "Utc",
          netSales: "-",
          grossProfit: "-",
          volume: "-",
          minNetRevPerMktUnit: "-",
          maxNetRevPerMktUnit: "-",
        },
      ],
    },
  };

  const inputFinConst = {
    "Potato Chips": {},
    "Unflavored Tortilla Chips": {},
    "Flavored Tortilla Chips": {},
    "Tortilla Chips": {},
    "Multi Packs": {},
    "Snack Mix": {},
    "Extruded Pellets": {},
    "Corn Chips": {},
  };

  scenarioData?.inputFinancialConstraints?.forEach((finCtx) => {
    inputFinConst[finCtx.subCategory] = finCtx;
  });

  scenario.financialConstraints.subCatFinConstraints.forEach((subFinCtx, idx) => {
    scenario.financialConstraints.subCatFinConstraints[idx].minGrossMargin =
      Number(inputFinConst[subFinCtx.subCategory].minGrossMargin || 0) * 100;
    scenario.financialConstraints.subCatFinConstraints[idx].minNetSales =
      Number(inputFinConst[subFinCtx.subCategory].minNetSales || 0) * 100;
    scenario.financialConstraints.subCatFinConstraints[idx].minVolume =
      Number(inputFinConst[subFinCtx.subCategory].minVolume || 0) * 100;

    if (inputFinConst[subFinCtx.subCategory].occasion?.toLowerCase() === "multipack") {
      scenario.financialConstraints.occasions.multiPack = true;
    }
    if (inputFinConst[subFinCtx.subCategory].occasion?.toLowerCase() === "take home") {
      scenario.financialConstraints.occasions.takeHome = true;
    }
  });

  const visualPriceCat = {};
  scenarioData?.inputBUSizePricingConstraints?.forEach((priceCat) => {
    if (!visualPriceCat[priceCat.subcategory]) {
      visualPriceCat[priceCat.subcategory] = {};
      visualPriceCat[priceCat?.subcategory][priceCat?.buSizeName] = {};
      visualPriceCat[priceCat?.subcategory][priceCat?.buSizeName] = { ...priceCat };
      visualPriceCat[priceCat?.subcategory][priceCat?.buSizeName]["include"] = true;
    } else {
      if (!visualPriceCat[priceCat?.subcategory][priceCat?.buSizeName]) {
        visualPriceCat[priceCat?.subcategory][priceCat?.buSizeName] = { ...priceCat };
        visualPriceCat[priceCat?.subcategory][priceCat?.buSizeName]["include"] = true;
      } else {
        visualPriceCat[priceCat?.subcategory][priceCat?.buSizeName] = {
          ...visualPriceCat[priceCat?.subcategory][priceCat?.buSizeName],
          ...priceCat,
        };
        visualPriceCat[priceCat?.subcategory][priceCat?.buSizeName]["include"] = true;
      }
    }
  });

  scenarioData?.inputBUSizeWeightConstraints?.forEach((weightOutData) => {
    if (!visualPriceCat[weightOutData.subcategory]) {
      visualPriceCat[weightOutData.subcategory] = {};
      visualPriceCat[weightOutData?.subcategory][weightOutData?.buSizeName] = {};
      visualPriceCat[weightOutData?.subcategory][weightOutData?.buSizeName]["weightOut"] = weightOutData.weightChange;
    } else {
      if (!visualPriceCat[weightOutData?.subcategory][weightOutData?.buSizeName]) {
        visualPriceCat[weightOutData?.subcategory][weightOutData?.buSizeName] = {};
        visualPriceCat[weightOutData?.subcategory][weightOutData?.buSizeName]["weightOut"] = weightOutData.weightChange;
      } else {
        visualPriceCat[weightOutData?.subcategory][weightOutData?.buSizeName]["weightOut"] = weightOutData.weightChange;
      }
    }
  });

  if (products.length > 0) {
    products.forEach((item) => {
      if (item.isPepsico !== false) {
        if (item.buSize && item.tier === "bu_size") {
          if (!scenario.scenarioCat[item.category]) {
            if (!buSizeFrequencies?.[item?.buSize]) {
              buSizeFrequencies[item?.buSize] = { freq: 0, min: 0, max: 12, include: false };
              buSizeFrequencies[item?.buSize].freq = 1;

              buSizeFrequencies[item?.buSize].min =
                buSizeFrequencies[item?.buSize].min === 0
                  ? visualPriceCat?.[item.category]?.[item.buSize]?.minPrice || 0
                  : buSizeFrequencies[item?.buSize].min;

              buSizeFrequencies[item?.buSize].max =
                buSizeFrequencies[item?.buSize].max === 12
                  ? visualPriceCat?.[item.category]?.[item.buSize]?.maxPrice || 12
                  : buSizeFrequencies[item?.buSize].max;

              buSizeFrequencies[item?.buSize].include =
                buSizeFrequencies[item?.buSize].include === false
                  ? visualPriceCat?.[item.category]?.[item.buSize]?.["include"] === true
                  : buSizeFrequencies[item?.buSize].include;
            } else {
              buSizeFrequencies[item?.buSize].freq += 1;

              buSizeFrequencies[item?.buSize].min =
                buSizeFrequencies[item?.buSize].min === 0
                  ? visualPriceCat?.[item.category]?.[item.buSize]?.minPrice || 0
                  : buSizeFrequencies[item?.buSize].min;

              buSizeFrequencies[item?.buSize].max =
                buSizeFrequencies[item?.buSize].max === 12
                  ? visualPriceCat?.[item.category]?.[item.buSize]?.maxPrice || 12
                  : buSizeFrequencies[item?.buSize].max;

              buSizeFrequencies[item?.buSize].include =
                buSizeFrequencies[item?.buSize].include === false
                  ? visualPriceCat?.[item.category]?.[item.buSize]?.["include"] === true
                  : buSizeFrequencies[item?.buSize].include;
            }

            scenario.scenarioCat[item.category] = {
              includedTotal: visualPriceCat?.[item.category] ? Object.keys(visualPriceCat?.[item?.category]).length : 0,
              category: item.category,
              parentMin: 0,
              parentMax: 12,
              parentMinValError: null,
              parentMaxValError: null,
              subCats: [
                {
                  productId: visualPriceCat?.[item.category]?.[item.buSize]?.pricingId || item.productId,
                  brand: item.brand,
                  brandUnit: item.brandUnit,
                  buSize: item.buSize,
                  linePriceGroup: visualPriceCat?.[item.category]?.[item.buSize]?.linePricingGroup || null,
                  weightOut: visualPriceCat?.[item.category]?.[item.buSize]?.["weightOut"] || 0,
                  include: buSizeFrequencies[item?.buSize].include,
                  Minimum: buSizeFrequencies[item?.buSize].min || 0,
                  Maximum: buSizeFrequencies[item?.buSize].max || 12,
                  minValError: null,
                  maxValError: null,
                  isPepsico: item.isPepsico,
                  actualPackSize: item.actualPackSize,
                },
              ],
            };
          } else {
            if (!scenario.scenarioCat[item.category].subCats.find((subCat) => subCat.buSize === item.buSize)) {
              if (!buSizeFrequencies?.[item?.buSize]) {
                buSizeFrequencies[item?.buSize] = { freq: 0, min: 0, max: 12, include: false };
                buSizeFrequencies[item?.buSize].freq = 1;

                buSizeFrequencies[item?.buSize].min =
                  buSizeFrequencies[item?.buSize].min === 0
                    ? visualPriceCat?.[item.category]?.[item.buSize]?.minPrice || 0
                    : buSizeFrequencies[item?.buSize].min;

                buSizeFrequencies[item?.buSize].max =
                  buSizeFrequencies[item?.buSize].max === 12
                    ? visualPriceCat?.[item.category]?.[item.buSize]?.maxPrice || 12
                    : buSizeFrequencies[item?.buSize].max;

                buSizeFrequencies[item?.buSize].include =
                  buSizeFrequencies[item?.buSize].include === false
                    ? visualPriceCat?.[item.category]?.[item.buSize]?.["include"] === true
                    : buSizeFrequencies[item?.buSize].include;
              } else {
                buSizeFrequencies[item?.buSize].freq += 1;

                buSizeFrequencies[item?.buSize].min =
                  buSizeFrequencies[item?.buSize].min === 0
                    ? visualPriceCat?.[item.category]?.[item.buSize]?.minPrice || 0
                    : buSizeFrequencies[item?.buSize].min;

                buSizeFrequencies[item?.buSize].max =
                  buSizeFrequencies[item?.buSize].max === 12
                    ? visualPriceCat?.[item.category]?.[item.buSize]?.maxPrice || 12
                    : buSizeFrequencies[item?.buSize].max;

                buSizeFrequencies[item?.buSize].include =
                  buSizeFrequencies[item?.buSize].include === false
                    ? visualPriceCat?.[item.category]?.[item.buSize]?.["include"] === true
                    : buSizeFrequencies[item?.buSize].include;
              }

              scenario.scenarioCat[item.category].subCats.push({
                productId: visualPriceCat?.[item.category]?.[item.buSize]?.pricingId || item.productId,
                brand: item.brand,
                brandUnit: item.brandUnit,
                buSize: item.buSize,
                linePriceGroup: visualPriceCat?.[item.category]?.[item.buSize]?.linePricingGroup || null,
                weightOut: visualPriceCat?.[item.category]?.[item.buSize]?.["weightOut"] || 0,
                include: buSizeFrequencies[item?.buSize].include,
                Minimum: buSizeFrequencies[item?.buSize].min || 0,
                Maximum: buSizeFrequencies[item?.buSize].max || 12,
                minValError: null,
                maxValError: null,
                isPepsico: item.isPepsico,
                actualPackSize: item.actualPackSize,
              });
            }
          }
        }
      }
    });
  }

  scenario.buSizeFrequencies = buSizeFrequencies;

  return scenario;
};

export const prepareApiData = (scenarioInfo: IAddScenario) => {
  const inputScope = [];
  const inputBUSizePricingConstraints = [];
  const inputBUSizeWeightConstraints = [];
  const inputFinancialConstraints = [];

  const industryMovement = {
    "Industry moves 100%": 1,
    "Industry moves 50%": 0.5,
    "No movement": 0,
    Yes: 1,
    No: 0,
  };

  const added = [];
  for (const category in scenarioInfo.scenarioCat) {
    scenarioInfo.scenarioCat[category].subCats.forEach((subCat) => {
      if (subCat.include && !added.includes(subCat.buSize)) {
        added.push(subCat.buSize);
        inputScope.push({ productBrandSize: subCat.buSize });
        inputBUSizePricingConstraints.push({
          buSizeName: subCat.buSize,
          subcategory: category,
          minPrice: subCat.Minimum,
          maxPrice: subCat.Maximum,
          linePricingGroup: subCat.linePriceGroup,
        });
      }

      if (scenarioInfo.isWeightChanges && subCat.weightOut !== 0) {
        inputBUSizeWeightConstraints.push({
          weightChange: subCat.weightOut,
          subcategory: category,
          buSizeName: subCat.buSize,
        });
      }
    });
  }

  scenarioInfo?.financialConstraints.subCatFinConstraints.forEach((item) => {
    if (scenarioInfo?.financialConstraints.occasions.takeHome && item.subCategory !== "Multi Packs") {
      inputFinancialConstraints.push({
        subCategory: item.subCategory,
        minNetSales: item.minNetSales / 100,
        minGrossMargin: item.minGrossMargin / 100,
        minVolume: item.minVolume / 100,
        maxNetRevenue: item.maxNetRevenue,
        occasion: "Take home",
      });
    }
    if (scenarioInfo?.financialConstraints.occasions.multiPack && item.subCategory === "Multi Packs") {
      inputFinancialConstraints.push({
        subCategory: item.subCategory,
        minNetSales: item.minNetSales / 100,
        minGrossMargin: item.minGrossMargin / 100,
        minVolume: item.minVolume / 100,
        maxNetRevenue: item.maxNetRevenue,
        occasion: "Multipack",
      });
    }
    if (
      !scenarioInfo?.financialConstraints.occasions.takeHome &&
      !scenarioInfo.financialConstraints.occasions.multiPack
    ) {
      inputFinancialConstraints.push({
        subCategory: item.subCategory,
        minNetSales: item.minNetSales / 100,
        minGrossMargin: item.minGrossMargin / 100,
        minVolume: item.minVolume / 100,
        maxNetRevenue: item.maxNetRevenue,
        occasion: "",
      });
    }
  });

  const data: Scenario = {
    scenarioId: scenarioInfo?.scenarioId || null,
    scenarioType: scenarioInfo?.scenarioType || "optimized",
    name: scenarioInfo.name,
    description: scenarioInfo.description,
    folder: {
      name: scenarioInfo.scenarioFolder.name,
      folderId: scenarioInfo.scenarioFolder.folderId,
    },
    maximizationGoal: scenarioInfo.optTarget,
    minNetRevPerMktUnit: scenarioInfo.financialConstraints.catFinConstraints.minNetRevenue,
    maxNetRevPerMktUnit: scenarioInfo.financialConstraints.catFinConstraints.maxNetRevenue,
    marketShare: scenarioInfo.financialConstraints.catFinConstraints.minMarketShare,
    competitorResponse: industryMovement[scenarioInfo.industry],
    isWeightChange: scenarioInfo.isWeightChanges,
    inputFinancialConstraints,

    inputPricingConstraints: {
      minPriceChange: 0,
      maxPriceChange: 0,
      stepPriceChange: 0,
      linePricingBrandSize: scenarioInfo.financialConstraints.pricingConstraints.brandSize,
      incentivesCurvesMinGap: scenarioInfo.financialConstraints.pricingConstraints.incentiveCurves,
    },

    inputScope,
    inputBUSizePricingConstraints,

    inputBUSizeWeightConstraints,
    isEmailNotification: scenarioInfo.isEmailNotification,
  };

  if (scenarioInfo.optTarget !== "cpg_revenue") {
    data.netSales = scenarioInfo.financialConstraints.catFinConstraints.minNetSales;
  }
  if (scenarioInfo.optTarget !== "cpg_profit") {
    data.grossMargin = scenarioInfo.financialConstraints.catFinConstraints.minGrossProfit;
  }
  if (scenarioInfo.optTarget !== "cpg_volume") {
    data.volume = scenarioInfo.financialConstraints.catFinConstraints.minVolume;
  }

  return data;
};

export const isNumeric = (value: any): boolean => !Number.isNaN(parseFloat(value)) && isFinite(value);

export const isEmpty = (value: any): boolean => value === "" || value === null || value === undefined;

export const firstLetterCapital = (word: string | number) => {
  word = word.toString();
  const words = word.split(" ");
  const possibleSizes = new Set(["xxl", "xl", "lg"]);
  let res = "";
  for (let i = 0; i < words.length; i++) {
    let temp = words[i].toLowerCase();
    if (possibleSizes.has(temp)) {
      temp = words[i].toUpperCase();
    } else {
      temp = temp.charAt(0).toUpperCase() + temp.slice(1);
    }
    res += temp + " ";
  }
  return res;
};

const formatNumber = (number: number) => {
  if (Math.abs(number) >= 1e9) {
    return `$${(number / 1e9).toFixed(2)}B`;
  } else if (Math.abs(number) >= 1e6) {
    return `$${(number / 1e6).toFixed(2)}M`;
  } else {
    return number.toString();
  }
};

export const getUpdateScenarioReferences = (
  defaultConstrCurrScenario: IScenarioReferences,
  constrFromRefScenario: IScenarioReferences,
) => {
  try {
    const temp = { ...constrFromRefScenario };
    const newReferences: IScenarioReferences = { ...defaultConstrCurrScenario };

    Object.keys(temp).forEach((key) => {
      if (temp[key] !== null) {
        if (key === "referenceFinancialConstraints") {
          Object.keys(temp[key]).forEach((value) => {
            if (temp[key][value] !== null) {
              newReferences.referenceFinancialConstraints[value] = formatNumber(temp[key][value]);
            }
          });
        } else if (key === "subCategoryFinancialConstraints") {
          temp[key].forEach((category, index) => {
            if (category && typeof category === "object") {
              // Create a new object to clone the category
              const newCategory = {
                category: "", // Provide a default value for category
                grossProfit: "-",
                netSales: "-",
                volume: "-",
                minNetRevPerMktUnit: "-",
                maxNetRevPerMktUnit: "-",
              };

              // Iterate through the properties of the category object
              Object.keys(category).forEach((value) => {
                if (category[value] !== null) {
                  if (value === "category") {
                    // Copy the "category" property as-is
                    newCategory[value] = category[value];
                  } else {
                    // Format and copy other properties
                    newCategory[value] = formatNumber(category[value]);
                  }
                }
              });

              // Add the cloned category object to newScenario.references.subCategoryFinancialConstraints
              newReferences.subCategoryFinancialConstraints[index] = newCategory;
            }
          });
        }
      }
    });

    return newReferences;
  } catch (error) {
    console.log(error);
  }
};

export const findBuSizeWo = (scenarioCat: Record<string, IAddScenarioCategories>) => {
  const buSizeWO: string[] = [];
  Object.keys(scenarioCat).forEach((indvCat) => {
    scenarioCat[indvCat].subCats.forEach((subCat) => {
      if (subCat.weightOut) {
        buSizeWO.push(subCat.buSize);
      }
    });
  });
  return buSizeWO;
};

export const hasSubCatBuSizeWO = (weightOut: number, buSize: string, buSizeWO: string[]) => {
  return Boolean(weightOut || buSizeWO.includes(buSize));
};
