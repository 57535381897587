export enum MarketName {
    PMF = "PMF",
    FLUS = "FLUS",
};

export type MarketIds = Record<MarketName, number>;

export interface MarketData {
    market: MarketName;
    id: number;
};