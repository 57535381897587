import homeFiltersReducer from "utils/redux/Home/homeFilters";
import resultReducer from "utils/redux/Optimization/resultSlice";
import supportReducer from "./support/supportSlice";
import maintenanceSlice from "utils/redux/maintenance/maintenanceSlice";
import addScenarioReducer from "./Optimization/addScenarioSlice";
import featureFlagsSlice from "./featureFlags/featureFlagsSlice";

export const rootReducer = {
  addScenario: addScenarioReducer,
  homeFilters: homeFiltersReducer,
  maintenance: maintenanceSlice,
  featureFlags: featureFlagsSlice,
  result: resultReducer,
  support: supportReducer,
};
