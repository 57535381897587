import { marketPermissions } from "constants/permissions";
import { MAIN_ROUTES } from "constants/routes";
import { AuthorizeUserContext } from "contexts/AuthorizeUserContext";
import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Loader } from "../Loader/Loader";

interface IProtectedRouteProps {
  element: React.ReactElement;
  permission: string;
}

const ProtectedRoute: React.FC<IProtectedRouteProps> = ({ element, permission }) => {
  const { authorizeUserDetails: user, isLoading } = useContext(AuthorizeUserContext);
  const [userHasRoutePermission, setUserHasRoutePermission] = useState<boolean | null>(null);

  useEffect(() => {
    if (!isLoading) {
      const filteredMarkets = marketPermissions.filter((market) =>
        user?.userAccessRoles?.some((role) => role.marketId === market.marketId && role.appId === 2),
      );
      setUserHasRoutePermission(filteredMarkets.some((market) => market[permission] === true));
    }
  }, [user, isLoading, permission]);

  // Show loading screen while user data is loading
  if (isLoading || userHasRoutePermission === null) {
    return <Loader isLoading={isLoading} />;
  }

  if (!userHasRoutePermission) {
    return <Navigate to={MAIN_ROUTES.NO_ACCESS} />;
  }

  return element;
};

export default ProtectedRoute;
