import { IProtectedRoutes } from "types/authorizeUser";

export const marketPermissions: IProtectedRoutes[] = [
  {
    marketId: 1,
    canAccessOptimization: true,
    canAccessCreateScenario: true,
    canAccessCompareScenario: true,
    canAccessResults: true,
    canAccessPricingSimulation: false,
    canAccessFolders: true,
    canAccessScenario: true,
    canAccessElasticities: true,
  },
  {
    marketId: 2,
    canAccessOptimization: true,
    canAccessCreateScenario: true,
    canAccessCompareScenario: true,
    canAccessResults: true,
    canAccessPricingSimulation: false,
    canAccessFolders: true,
    canAccessScenario: true,
    canAccessElasticities: true,
  },
  {
    marketId: 3,
    canAccessOptimization: false,
    canAccessCreateScenario: false,
    canAccessCompareScenario: false,
    canAccessResults: false,
    canAccessPricingSimulation: true,
    canAccessFolders: false,
    canAccessScenario: false,
    canAccessElasticities: true,
  },
];
